<!-- 时间类型选择器   prop值： type传入初始值类型 1 周 2 月 3年 dateValue传入具体时间初始值  不传默认是 "请选择"  -->
<style scoped>
.picker {
  width: 240px;
  height: 46px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.picker .option >>> .el-input__inner {
  width: 90px;
  box-sizing: border-box;
  border-radius: 5px 0 0 5px;
}
.picker .date >>> .el-input__inner {
  border-radius: 0 5px 5px 0px;
}
/* .el-select .el-input.is-focus .el-input__inner{
    
} */
</style>
<template>
  <div class="picker">
    <el-select
      v-model="time_type"
      placeholder="请选择"
      class="option"
      @change="selectChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <el-date-picker
      @change="dateChange"
      class="date"
      value-format="yyyy-MM-dd"
      v-model="date.dateValue"
      v-if="time_type == 1"
      type="week"
      format="yyyy 第 WW 周"
      placeholder="选择周"
    >
    </el-date-picker>
    <el-date-picker
      @change="dateChange"
      class="date"
      v-model="date.dateValue"
      type="month"
      value-format="yyyy-MM"
      v-else-if="time_type == 2"
      placeholder="选择月"
    >
    </el-date-picker>
    <el-date-picker
      @change="dateChange"
      class="date"
      v-model="date.dateValue"
      value-format="yyyy"
      v-else
      type="year"
      placeholder="选择年"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { getNextDate } from "@/utils/index";
export default {
  name: "datepicker",
  props:['type','dateValue'],
  data() {
    return {
      show: false,
      options: [
        {
          value: "1",
          label: "周",
        },
        {
          value: "2",
          label: "月",
        },
        {
          value: "3",
          label: "年",
        },
      ],
      time_type: null,
      date: {
        dateValue: null,
        from_time: null,
        to_time: null,
      },
    };
  },
  created(){
      this.time_type = this.type
      this.date.dateValue = this.dateValue
  },
  methods: {
    //下拉选择   1 周 2 月  3 年
    selectChange(e) {
      //   console.log(e)
      this.time_type = e;
      this.date.dateValue = null
      this.date.from_time = null
      this.date.to = null
      this.$emit("typeChange", this.time_type);
    //   this.$emit("dateChange", this.date);   
    },
    //时间选择器 选择
    dateChange(e) {
      //    console.log(e)
      this.date.dateValue = e;
      console.log(e)
      if (this.time_type == 1) {
        //如果是周的情况  e的值是周一的日期
        this.date.from_time = e;
        this.date.to_time = getNextDate(e, 6);
      } else {
        this.date.from_time = e;
        this.date.to_time = e;
      }
      this.$emit("dateChange", this.date);
    },
  },
};
</script>

